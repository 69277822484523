import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'

import SEO from '../components/seo'
import BlogPostItem from '../components/blog-post-item'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import { Link } from 'gatsby'

import { TestimonialVideo } from '../components/testimonial_video'
import ContactForm from '../components/estimate-form'
import { StartWithAFreeQuote } from '../components/start-with-a-free-quote'

import Breadcrumbs from '../components/breadcrumbs'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
  align-items: stretch;
`

const Heading = styled.div`
  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const TestimonialsPage = (props) => (
  <StaticQuery
    query={graphql`
      query getAllTestimonialVideos {
        allContentfulTestimonialVideoPost {
          edges {
            node {
              id
              slug
              createdAt
              category {
                name
                slug
              }
              video {
                id
                title
                file {
                  url
                  contentType
                }
              }
              projectReference {
                slug
              }
              thumbnail {
                id
                file {
                  url
                }
                gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
              }
            }
          }
        }
        contactFormSideImage: contentfulAsset(
          title: { eq: "Bathroom Remodeling Seattle" }
        ) {
          id
          title
          gatsbyImageData
        }
        allContentfulTestimonialCategory {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <Layout pageProps={props}>
            <div className="border-b border-gray-100">
              <nav
                className="flex container mx-auto px-4 py-3"
                aria-label="Breadcrumb"
              >
                <Breadcrumbs
                  links={[
                    { to: '/', label: 'Home' },
                    { to: props.location.pathname, label: 'Testimonials' },
                  ]}
                />
              </nav>
            </div>
            <SEO
              title={'Testimonials | Seattle Renovation Group'}
              description={
                'Read some testimonials and reviews about Seattle Renovation Group'
              }
            />
            <Heading style={{ paddingTop: '1em' }}>
              <h1>Project Videos</h1>
              <p>Check out some of our recently completed projects.</p>
            </Heading>
            <BlogPostsLayoutContainer className="container pt-2">
              {data.allContentfulTestimonialVideoPost.edges.map(
                ({ node: testimonialVideo }, i) => (
                  <TestimonialVideo
                    columnsClassName="col-lg-4"
                    {...testimonialVideo}
                    showTitle
                  />
                )
              )}
            </BlogPostsLayoutContainer>
            <StartWithAFreeQuote {...props} data={data} />
            <SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
          </Layout>
        </>
      )
    }}
  />
)

export default TestimonialsPage
